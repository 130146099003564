import { request, noTimeOutReq } from '@/utils/request.js'

//获取考点考试信息
export function getPlaceExamInfoApi (examId, _sysOrgExamPlaceIdList) {
  return request({
    url: `/company/join/exam/info/get/place/examInfo/${examId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: _sysOrgExamPlaceIdList
  })
}

//获取考点考生考试结果上传列表
export function getPlaceExamineeResultApi (pageIndex, pageSize, examId, data) {
  return request({
    url: `/company/join/exam/info/list/place/examinee/result/${pageIndex}/${pageSize}/${examId}`,
    method: 'post',
    data: data
  })
}

//获取考点考生考试结果上传列表条件
export function getPlaceExamineeResultConditionApi (examId, _sysOrgExamPlaceIdList) {
  return request({
    url: `/company/join/exam/info/list/place/examinee/result/condition/${examId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: _sysOrgExamPlaceIdList
  })
}

//确认考后数据上传完成
export function confirmUploadFinishApi (examId) {
  return noTimeOutReq({
    url: `/company/school/platform/${examId}/data/upload/finish`,
    method: 'post',
  })
}

//点击 上传考后【视频】数据，视频单独上传
export function uploadVideoDataApi (examId) {
  return noTimeOutReq({
    url: `/company/school/platform/data/video/exam/upload/${examId}`,
    method: 'post',
  })
}

//点击 上传考后【答题】数据，不包含视频
export function uploadAnswerDataApi (examId) {
  return noTimeOutReq({
    url: `/company/school/platform/data/answer/exam/upload/${examId}`,
    method: 'post',
  })
}

//上传单个考生【答题】数据，不包含视频
export function uploadOneAnswerDataApi (examineeId) {
  return request({
    url: `/company/school/platform/data/answer/examinee/upload/${examineeId}`,
    method: 'post',
  })
}

//上传单个考生【视频】数据，不包含视频
export function uploadOneVideoDataApi (examineeId) {
  return request({
    url: `/company/school/platform/data/video/examinee/upload/${examineeId}`,
    method: 'post',
  })
}

//上传单个考生【抽签】数据，座位号和考题信息
export function uploadOneDrawDataApi (examineeId) {
  return request({
    url: `/company/school/platform/data/seat/examinee/upload/${examineeId}`,
    method: 'post',
  })
}




//上传考生成绩数据
export function uploadOneGradesDataApi (examineeId) {
  return request({
    url: `/company/school/platform/${examineeId}/mark/record/upload`,
    method: 'post',
  })
}

