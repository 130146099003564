<template>
  <div>
    <div>
      <div>
        <el-button v-throttle size="medium" @click="goBack">返回</el-button>
      </div>

      <div id="exam-name-box">
        <span>{{ placeExamInfo.examName }}</span>
      </div>

      <div style="margin: 15px 0">
        <span
          v-if="
            placeExamInfo.examStartTime != null &&
            placeExamInfo.examStartTime != 0
          "
          >{{ placeExamInfo.examStartTime | dateFormat }}</span
        >
        <span
          v-if="
            placeExamInfo.examStartTime == null ||
            placeExamInfo.examStartTime == 0
          "
          >未开始</span
        >
        <span>——</span>
        <span
          v-if="
            placeExamInfo.examEndTime != null && placeExamInfo.examEndTime != 0
          "
          >{{ placeExamInfo.examEndTime | dateFormat }}</span
        >
        <span
          v-if="
            placeExamInfo.examEndTime == null || placeExamInfo.examEndTime == 0
          "
          >未结束</span
        >
      </div>

      <div>
        <span>
          科目：
          <span v-for="(item, i) in placeExamInfo.subjectTypeList" :key="i">
            <span v-if="item == 1">物理</span>
            <span v-else-if="item == 2">生物</span>
            <span v-else-if="item == 3">化学</span>
            <span v-if="i < placeExamInfo.subjectTypeList.length - 1">,</span>
          </span>
        </span>
        <span class="header-count-badge"
          >考生人数：{{ placeExamInfo.examineeCount }}人</span
        >
        <span class="header-count-badge">
          考点：
          <span
            v-for="(item, index) in placeExamInfo.sysOrgExamPlaceNameList"
            :key="index"
          >
            <span>{{ item }}</span>
            <span
              v-if="index < placeExamInfo.sysOrgExamPlaceNameList.length - 1"
              >,</span
            >
          </span>
        </span>
        <span class="header-count-badge" v-show="!!placeExamInfo.examAnswerTime"
          >考试时长：{{ placeExamInfo.examAnswerTime }}min</span
        >
        <span class="header-count-badge"
          >考试批次：{{ placeExamInfo.examSessionCount }}批</span
        >
      </div>

      <div style="margin: 15px 0">
        <el-button
          v-throttle="true"
          type="primary"
          size="medium"
          @click="uploadAnswerData"
          :disabled="placeExamInfo.gmtResultConfirm"
          >上传考后(文件)数据</el-button
        >
        <el-button
          v-throttle="true"
          type="primary"
          size="medium"
          @click="uploadVideoData"
          :disabled="placeExamInfo.gmtResultConfirm"
          >上传考后(视频)数据</el-button
        >
        <el-button
          v-throttle="true"
          type="primary"
          size="medium"
          @click="confirmUploadFinish"
          :disabled="placeExamInfo.gmtResultConfirm"
          >确认考后数据</el-button
        >
        <el-button
          v-throttle="true"
          type="primary"
          size="medium"
          @click="exportData"
          :disabled="placeExamInfo.gmtResultConfirm"
          >导出考试数据</el-button
        >
      </div>

      <div>
        校级确认时间：
        <span v-if="placeExamInfo.gmtResultConfirm">{{
          placeExamInfo.gmtResultConfirm | dateFormat
        }}</span>
        <span v-else>未确认</span>
      </div>
    </div>

    <div id="score-info-box">
      <el-card shadow="always">
        <div>
          <el-form :inline="true" :model="queryParam">
            <el-form-item label="考生查询">
              <el-input
                style="width: 190px"
                v-model="queryParam.studentName"
                placeholder="输入姓名/考号"
                @input="reloadPlaceExamineeResult"
              ></el-input>
            </el-form-item>

            <!-- {{placeExamineeResultCondition}} -->
            <el-form-item label="学校">
              <el-select
                v-model="queryParam.sysOrgSchoolId"
                placeholder="请选择学校"
                @change="chooseSchool"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="item in placeExamineeResultCondition.examineeResultSchoolConditionList"
                  :key="item.sysOrgSchoolId"
                  :label="item.sysOrgSchoolName"
                  :value="item.sysOrgSchoolId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="班级">
              <el-select
                v-model="queryParam.sysOrgSchoolClassId"
                placeholder="请选择班级"
                @change="reloadPlaceExamineeResult"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="item in examineeResultSchoolClassConditionList"
                  :key="item.sysOrgSchoolClassId"
                  :label="item.sysOrgSchoolClassName"
                  :value="item.sysOrgSchoolClassId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="考试场次">
              <el-cascader
                placeholder="全部"
                ref="cascader"
                :options="
                  placeExamineeResultCondition.examineeResultPlaceConditionList
                "
                @change="sessionInfoChange"
                v-model="selectCascader"
              ></el-cascader>
            </el-form-item>

            <el-form-item label="科目">
              <el-select
                v-model="queryParam.subjectType"
                placeholder="请选择科目"
                @change="reloadPlaceExamineeResult"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="item in subjectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="上传情况">
              <el-select
                v-model="queryParam.uploadStatus"
                placeholder="请选择上传情况"
                @change="reloadPlaceExamineeResult"
              >
                <el-option label="全部" value></el-option>
                <el-option label="未完成" :value="false"></el-option>
                <el-option label="已完成" :value="true"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                v-throttle
                type="primary"
                icon="el-icon-refresh"
                @click="refresh"
              ></el-button>
            </el-form-item>
          </el-form>
        </div>

        <div>
          <el-table
            stripe
            border
            :data="placeExamineeResult"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            v-loading="loading"
          >
            <el-table-column
              prop="studentName"
              label="考生姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeCode"
              label="考号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="sysOrgAreaName"
              label="区县"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="sysOrgSchoolName"
              label="学校"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examSessionInfo"
              label="考试批次"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="subjectType"
              label="科目"
              show-overflow-tooltip
              :min-width="30"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.subjectType == 1">物理</span>
                <span v-else-if="scope.row.subjectType == 2">生物</span>
                <span v-else-if="scope.row.subjectType == 3">化学</span>
                <span v-else>未知</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="examQuestionOperationTest"
              label="考题"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="uploadStatus"
              label="上传情况"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="!!scope.row.uploadStatus">已完成</span>
                <span v-else>未完成</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="videosStatus"
              label="视频上传状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="!!scope.row.videosStatus">已上传</span>
                <span v-else>未上传</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="otherDataStatus"
              label="其他资料上传状态"
              show-overflow-tooltip
              width="140px"
            >
              <template slot-scope="scope">
                <span v-if="!!scope.row.otherDataStatus">已上传</span>
                <span v-else>未上传</span>
              </template>
            </el-table-column>
            <!-- <el-table-column v-if="!btnHide" prop="achievementStatus" label="成绩上传状态" show-overflow-tooltip width="140px">
              <template slot-scope="scope">
                <span v-if="scope.row.achievementStatus">已上传</span>
                <span v-else>未上传</span>
              </template>
            </el-table-column> -->
            <el-table-column
              label="操作"
              width="300px"
              v-if="!placeExamInfo.gmtResultConfirm"
            >
              <template slot-scope="scope">
                <!-- <el-button v-throttle  size="mini" type="primary" @click="uploadOneDrawData(scope.row)">上传抽签</el-button> -->
                <el-button
                  v-throttle
                  size="mini"
                  type="primary"
                  @click="uploadOneAnswerData(scope.row)"
                  >上传文件</el-button
                >
                <el-button
                  v-throttle
                  size="mini"
                  type="primary"
                  @click="uploadOneVideoData(scope.row)"
                  >上传视频</el-button
                >
                <el-button
                  v-throttle
                  size="mini"
                  type="primary"
                  v-if="!btnHide"
                  @click="uploadOneGradesData(scope.row)"
                  >上传成绩</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageParam.pageIndex"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  getPlaceExamInfoApi,
  getPlaceExamineeResultApi,
  getPlaceExamineeResultConditionApi,
  confirmUploadFinishApi,
  uploadVideoDataApi,
  uploadAnswerDataApi,
  uploadOneAnswerDataApi,
  uploadOneVideoDataApi,
  uploadOneDrawDataApi,
  uploadOneGradesDataApi,
} from '@/api/schoolManufacturer/afterExamination.js'
import { exportExamination } from '@/api/marking/markingManage.js'
import { isEmpty, debounce } from '@/utils/util.js'
import { getStore } from '@/utils/token.js'
export default {
  name: 'afterExamination',
  data() {
    return {
      // 隐藏按钮
      btnHide: true,
      //
      queryParam: {
        studentName: '',
        sysOrgExamPlaceId: '',
        sysOrgExamPlaceRoomId: '',
        sysOrgSchoolId: '',
        sysOrgSchoolClassId: '',
        examSession: '',
        subjectType: '',
        uploadStatus: '',
      },
      queryDissentParam: {
        name: '',
        gender: 'shanghai',
      },
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      urlParam: {
        examId: 0,
        sysOrgExamPlaceIdList: [],
      },
      subjectOptions: [],
      placeExamInfo: {},
      placeExamineeResult: [],
      placeExamineeResultCondition: {
        examineeResultSchoolConditionList: [],
        examineeResultPlaceConditionList: [
          {
            value: '',
            label: '',
            children: [
              {
                value: '',
                label: '',
                children: [
                  {
                    value: '',
                    label: '',
                  },
                ],
              },
            ],
          },
        ],
      },
      examineeResultSchoolClassConditionList: [],
      selectCascader: [],
      loading: false,
    }
  },
  mounted() {
    this.urlParam.examId = this.$route.query.examId
    this.urlParam.sysOrgExamPlaceIdList = getStore('sysOrgExamPlaceIdList')
    this.getPlaceExamInfo()
    this.getPlaceExamineeResult()
    this.getPlaceExamineeResultCondition()
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/examManage',
        query: {},
      })
    },
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions.filter((val) => {
        return this.placeExamInfo.subjectTypeList.indexOf(val.value) != -1
      })
    },
    //获取考点考试信息
    getPlaceExamInfo() {
      let listReturn = []
      let flag = Array.isArray(this.urlParam.sysOrgExamPlaceIdList)
      if (flag) {
        listReturn = this.urlParam.sysOrgExamPlaceIdList
      } else {      
        listReturn = this.urlParam.sysOrgExamPlaceIdList.split(',').map(e=>+e)
        // listReturn.push(this.urlParam.sysOrgExamPlaceIdList)
      }

      getPlaceExamInfoApi(this.urlParam.examId, listReturn)
        .then((res) => {
          if (res.success) {
            this.placeExamInfo = {}
            this.placeExamInfo = res.data
            if (
              this.placeExamInfo.markType == 1 ||
              this.placeExamInfo.markType == null
            ) {
              this.btnHide = true
            } else {
              this.btnHide = false
            }
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
          this.renderSelect()
        })
        .catch(() => {})
    },
    //获取考点考生考试结果上传列表
    getPlaceExamineeResult() {
      this.loading = true
      getPlaceExamineeResultApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.urlParam.examId,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.placeExamineeResult = []
            this.placeExamineeResult = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //获取考点考生考试结果上传列表条件
    getPlaceExamineeResultCondition() {
      let listReturn = []
      let flag = Array.isArray(this.urlParam.sysOrgExamPlaceIdList)
      if (flag) {
        listReturn = this.urlParam.sysOrgExamPlaceIdList
      } else {
        listReturn = this.urlParam.sysOrgExamPlaceIdList.split(',').map(e=>+e)
        // listReturn.push(this.urlParam.sysOrgExamPlaceIdList)
      }
      getPlaceExamineeResultConditionApi(this.urlParam.examId, listReturn).then(
        (res) => {
          if (res.success) {
            this.placeExamineeResultCondition = {}
            this.placeExamineeResultCondition = res.data
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        }
      )
    },
    //选择学校
    chooseSchool(val) {
      this.placeExamineeResultCondition.examineeResultSchoolConditionList.forEach(
        (item) => {
          if (item.sysOrgSchoolId == val) {
            //渲染筛选班级
            this.examineeResultSchoolClassConditionList = []
            this.examineeResultSchoolClassConditionList =
              item.examineeResultSchoolClassConditionList
          }
        }
      )
      this.reloadPlaceExamineeResult()
    },
    //选择批次
    sessionInfoChange(nodeList) {
      this.queryParam.sysOrgExamPlaceId = nodeList[0]
      this.queryParam.sysOrgExamPlaceRoomId = nodeList[1]
      this.queryParam.examSession = nodeList[2]
      this.reloadPlaceExamineeResult()
    },
    refresh() {
      this.queryParam.studentName = ''
      this.queryParam.sysOrgExamPlaceId = ''
      this.queryParam.sysOrgExamPlaceRoomId = ''
      this.queryParam.sysOrgSchoolId = ''
      this.queryParam.sysOrgSchoolClassId = ''
      this.queryParam.examSession = ''
      this.queryParam.subjectType = ''
      this.queryParam.uploadStatus = ''
      this.queryParam.examSession = ''
      // this.$refs.cascader.clearCheckedNodes();
      this.selectCascader = []
      this.reloadPlaceExamineeResult()
    },
    reloadPlaceExamineeResult() {
      this.pageParam.pageIndex = 1
      this.getPlaceExamineeResult()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getPlaceExamineeResult()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getPlaceExamineeResult()
    },
    // //确认考后数据上传完成
    // confirmUploadFinish() {
    //   const loading = this.globalLoading('正在确认数据...')
    //   confirmUploadFinishApi(this.urlParam.examId)
    //     .then((res) => {
    //       loading.close()
    //       if (res.success) {
    //         this.msgSuccess('确认成功')
    //         this.getPlaceExamInfo()
    //       } else {
    //         if (res.code != 500) {
    //           this.msgWarning(res.msg)
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       loading.close()
    //     })
    // },
    //确认考后数据上传完成
    confirmUploadFinish: debounce(function () {
      const loading = this.globalLoading('正在确认数据...')
      confirmUploadFinishApi(this.urlParam.examId)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('确认成功')
            this.getPlaceExamInfo()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    }, 300),
    // //点击 上传考后【视频】数据，视频单独上传
    // uploadVideoData() {
    //   const loading = this.globalLoading('正在上传...')
    //   uploadVideoDataApi(this.urlParam.examId)
    //     .then((res) => {
    //       loading.close()
    //       if (res.success) {
    //         this.msgSuccess('上传成功')
    //       } else {
    //         if (res.code != 500) {
    //           this.msgWarning(res.msg)
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       loading.close()
    //     })
    // },
    //点击 上传考后【视频】数据，视频单独上传
    uploadVideoData: debounce(function () {
      const loading = this.globalLoading('正在上传...')
      uploadVideoDataApi(this.urlParam.examId)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('开始上传')
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    }, 300),
    // //点击 上传考后【答题】数据，不包含视频
    // uploadAnswerData() {
    //   const loading = this.globalLoading('正在上传...')
    //   uploadAnswerDataApi(this.urlParam.examId)
    //     .then((res) => {
    //       loading.close()
    //       if (res.success) {
    //         this.msgSuccess('上传成功')
    //       } else {
    //         if (res.code != 500) {
    //           this.msgWarning(res.msg)
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       loading.close()
    //     })
    // },
    //点击 上传考后【答题】数据，不包含视频
    uploadAnswerData: debounce(function () {
      const loading = this.globalLoading('正在上传...')
      uploadAnswerDataApi(this.urlParam.examId)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('开始上传')
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    }, 300),
    //上传单个考生【答题】数据，不包含视频
    uploadOneAnswerData(val) {
      const loading = this.globalLoading('正在上传...')
      uploadOneAnswerDataApi(val.examineeId)
        .then((res) => {
          loading.close()
          if (res.success) {
            if (res.data) {
              this.msgSuccess('上传成功')
              this.getPlaceExamineeResult()
            } else {
              this.msgWarning('没有考生的答题记录')
            }
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //上传单个考生【视频】数据，不包含视频
    uploadOneVideoData(val) {
      const loading = this.globalLoading('正在上传...')
      uploadOneVideoDataApi(val.examineeId)
        .then((res) => {
          loading.close()
          if (res.success) {
            if (res.data) {
              this.msgSuccess('上传成功')
              this.getPlaceExamineeResult()
            } else {
              this.msgWarning('没有考生的答题记录')
            }
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    //上传单个考生【抽签】数据，座位号和考题信息
    uploadOneDrawData(val) {
      const loading = this.globalLoading('正在上传...')
      uploadOneDrawDataApi(val.examineeId)
        .then((res) => {
          loading.close()
          if (res.success) {
            if (res.data) {
              this.msgSuccess('上传成功')
              // this.getPlaceExamineeResult();
            } else {
              this.msgWarning('没有考生的答题记录')
            }
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          loading.close()
        })
    },
    // 上传成绩按钮
    uploadOneGradesData(val) {
      const loading = this.globalLoading('正在上传...')
      uploadOneGradesDataApi(val.examineeId)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('上传成功')
          } else if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        })
        .catch((err) => {
          loading.close()
        })
    },
    // //导出考试数据
    // exportData() {
    //   this.loading = true
    //   exportExamination(this.urlParam.examId)
    //     .then((res) => {
    //       let { success, msg } = res
    //       this.loading = false
    //       if (success) {
    //         this.getPlaceExamInfo()
    //         this.getPlaceExamineeResult()
    //         this.getPlaceExamineeResultCondition()
    //         this.$message.success('导出数据成功')
    //       } else {
    //         this.$message.error(msg)
    //       }
    //     })
    //     .catch((err) => {
    //       this.loading = false
    //     })
    // },
    //导出考试数据
    exportData: debounce(function () {
      this.loading = true
      exportExamination(this.urlParam.examId)
        .then((res) => {
          let { success, msg } = res
          this.loading = false
          if (success) {
            this.getPlaceExamInfo()
            this.getPlaceExamineeResult()
            this.getPlaceExamineeResultCondition()
            this.$message.success('导出数据成功')
          } else {
            this.$message.error(msg)
          }
        })
        .catch((err) => {
          this.loading = false
        })
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
#exam-name-box {
  margin-top: 15px;
}

#exam-name-box > span {
  font-size: 28px;
}

.header-count-badge {
  margin-left: 30px;
}

#score-info-box ::v-deep .el-form .el-form-item__content .el-select {
  width: 190px;
}

#score-info-box,
#score-dissent-info-box {
  margin-top: 20px;
}

#score-info-box > p {
  color: #909399;
}

#score-dissent-info-box > p {
  font-weight: bold;
}

.inputScore {
  width: 60px;
}

.inputScore > ::v-deep .el-input__inner {
  text-align: center;
}
</style>
